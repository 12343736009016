import React, { useEffect } from 'react';
import { navigate } from "gatsby";
import { useDispatch, useSelector } from 'react-redux';
import { FormEstudianteNuevo } from './estudiante/FormEstudianteNuevo';
import { FormEstudianteActualizar } from './estudiante/FormEstudianteActualizar';
import { FormEstudianteCredencialNueva } from './credencial/FormEstudianteCredencialNueva';
import { FormEstudianteCredencialActualizar } from './credencial/FormEstudianteCredencialActualizar';
import { FormEstudianteInformacionNuevo } from './informacion/FormEstudianteInformacionNuevo';
import { FormEstudianteInformacionActualizar } from './informacion/FormEstudianteInformacionActualizar';
import { FormEstudianteDocumentacionNuevo } from './informacion/FormEstudianteDocumentacionNuevo';
import { FormEstudianteDocumentacionActualizar } from './informacion/FormEstudianteDocumentacionActualizar';
import { FormEstudianteSaludNuevo } from './informacion/FormEstudianteSaludNuevo';
import { FormEstudianteSaludActualizar } from './informacion/FormEstudianteSaludActualizar';
import { TablaEstudiantes } from './estudiante/TablaEstudiantes';
import { FormEstudianteHistoricoNuevo } from './informacion/FormEstudianteHistoricoNuevo';
import { FormEstudianteHistoricoActualizar } from './informacion/FormEstudianteHistoricoActualizar';
import { obtenerEstablecimientos } from '../../action/establecimiento';
import { TablaEstablecimientos } from './establecimiento/TablaEstablecimientos';
import { BotonNuevoEstudiante } from './BotonNuevoEstudiante';
import { obtenerEstudiantes } from '../../action/estudiante';
import { TablaCredenciales } from './credencial/TablaCredenciales';
import { BotonNuevaCredencial } from './BotonNuevaCredencial';


export const AdministradorEstudianteScreen = () => {
  const dispatch = useDispatch()
  const { uid } = useSelector( state => state.auth );
  const { establecimientos, establecimientoActivo } = useSelector( state => state.establecimiento );
  const { estudiantes, estudianteActivo, credencialesEstudiante, credencialEstudianteActiva } = useSelector( state => state.estudiante );
  const {
    estudianteTablaEstablecimientos,
    estudianteTablaEstudiantes,
    estudianteFicha,
    estudianteFormNuevo,
    estudianteFormActualizar,
    estudianteTablaCredenciales,
    estudianteFormCredencialNueva,
    estudianteFormCredencialActualizar,
    estudianteFormInformacionNueva,
    estudianteFormInformacionActualizar,

  } = useSelector(state => state.router);

  useEffect(() => {
    const obtenerEstablecimientosPrincipal = () => dispatch(obtenerEstablecimientos());
    const obtenerEstudiantesPrincipal = () => dispatch(obtenerEstudiantes());
    obtenerEstablecimientosPrincipal()
    obtenerEstudiantesPrincipal()
    // eslint-disable-next-line
  }, []);

  if(!uid){
    setTimeout(() => {
      navigate("/login/")
    }, 1500);
  }

  return (
    <div className='row'>
      <div className='col-12'>
        <div className='h3 text-light'>Administrador estudiante</div>
      </div>
      <div className='col-12'>
        <div className='row'>
          {estudianteTablaEstudiantes && 
            <div className='col-3'>
              <BotonNuevoEstudiante />
            </div>
          }
          {estudianteTablaCredenciales && 
            <div className='col-3'>
              <BotonNuevaCredencial />
            </div>
          }
        </div>
      </div>
      <div className='col-12'>
        {estudianteTablaEstablecimientos && <TablaEstablecimientos establecimientos={establecimientos} />}
        {estudianteTablaEstudiantes && <TablaEstudiantes establecimientoActivo={establecimientoActivo} estudiantes={estudiantes} />}
        {estudianteFormNuevo && <FormEstudianteNuevo />}
        {estudianteFormActualizar && <FormEstudianteActualizar estudianteActivo={estudianteActivo} />}
        {estudianteTablaCredenciales && <TablaCredenciales estudianteActivo={estudianteActivo} estudiantes={estudiantes} credencialesEstudiante={credencialesEstudiante} establecimientos={establecimientos} establecimientoActivo={establecimientoActivo} />}
        {estudianteFormCredencialNueva && <FormEstudianteCredencialNueva estudianteActivo={estudianteActivo} establecimientoActivo={establecimientoActivo} />}
        {estudianteFormCredencialActualizar && <FormEstudianteCredencialActualizar credencialEstudianteActiva={credencialEstudianteActiva} />}
        

        {/* 
        

        <FormEstudianteInformacionNuevo />
        <FormEstudianteInformacionActualizar />
        <FormEstudianteDocumentacionNuevo />
        <FormEstudianteDocumentacionActualizar />
        <FormEstudianteSaludNuevo />
        <FormEstudianteSaludActualizar />
        <FormEstudianteHistoricoNuevo />
        <FormEstudianteHistoricoActualizar /> */}
      </div>
    </div>
  )
}
