import Swal from "sweetalert2";
import { fetchConToken, fetchFiltro, fetchSinToken } from "../helpers/fetch";
import { types } from "../types/types";



export const crearCredencialEstudiante = ( credencialEstudiante ) => {
    return async ( dispatch ) => {
        try {
            const resp = await fetchSinToken('establecimiento/crearCredencialEstudiante', credencialEstudiante, 'POST');
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const {result, message } = body;
                dispatch(crearCredencialEstudianteOk( result.payload ))
                Swal.fire({
                    icon: 'success',
                    title: message,
                    showConfirmButton: false,
                    timer: 1000
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 2000
                });
                dispatch(crearCredencialEstudianteError());
            }
        } catch (error) {
            console.log(error);
            dispatch(crearCredencialEstudianteError());
        }
    }
}

const crearCredencialEstudianteOk = (e) => ({
    type: types.crearCredencialEstudianteOk,
    payload: e
});

const crearCredencialEstudianteError = () => ({ type: types.crearCredencialEstudianteError });

export const obtenerCredencialesEstudianteEstablecimiento = (filtro) => {
    return async (dispatch) => {
        try {
            const resp = await fetchFiltro('establecimiento/obtenerCredencialesEstudianteEstablecimiento', filtro);
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const {result } = body;
                dispatch(obtenerCredencialesEstudianteOk( result.payload ))
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 2000
                });
                dispatch(obtenerCredencialesEstudianteError());
            }
        } catch (error) {
            console.log(error)
            dispatch(obtenerCredencialesEstudianteError());
        }
    }
}

export const obtenerCredencialesEstudiante = (filtro) => {
    return async (dispatch) => {
        try {
            const resp = await fetchFiltro('establecimiento/obtenerCredencialesEstudiante', filtro);
            const body = await resp.json();
            if(body.code === 'MNS.RMS.0200'){
                const {result, message } = body;
                dispatch(obtenerCredencialesEstudianteOk( result.payload ))
                Swal.fire({
                    icon: 'success',
                    title: message,
                    showConfirmButton: false,
                    timer: 1000
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 2000
                });
                dispatch(obtenerCredencialesEstudianteError());
            }
        } catch (error) {
            console.log(error)
            dispatch(obtenerCredencialesEstudianteError());
        }
    }
}

const obtenerCredencialesEstudianteOk = (e) => ({ 
    type: types.obtenerCredencialesEstudianteOk ,
    payload: e
});

const obtenerCredencialesEstudianteError = () => ({ type: types.obtenerCredencialesEstudianteError })

export const actualizarCredencialEstudiante = (credencial) => {
    return async ( dispatch ) => {
        try {
            const resp = await fetchConToken(`establecimiento/actualizarCredencialEstudiante/${credencial._id}`, credencial, 'PUT');
            const body = await resp.json();
            console.log(body)
            if(body.code === 'MNS.RMS.0200'){
                const {result } = body;
                dispatch(actualizarCredencialEstudianteOk( result.payload ))
            } else {
                Swal.fire({
                    icon: 'error',
                    title: body.message,
                    showConfirmButton: false,
                    timer: 2000
                });
                dispatch(actualizarCredencialEstudianteError());
            }

        } catch (error) {
            console.log(error)
            dispatch(actualizarCredencialEstudianteError())
        }
    }
}

const actualizarCredencialEstudianteOk = (e) => ({ 
    type: types.actualizarCredencialEstudianteOk,
    payload: e
});

const actualizarCredencialEstudianteError = () => ({ type : types.actualizarCredencialEstudianteError });

export const activarCredencialEstudiante = (e) => ({ 
    type: types.activarCredencialEstudiante,
    payload: e
});

export const limpiarCredencialesEstudiante = () => ({ type: types.limpiarCredencialesEstudiante });