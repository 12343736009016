import React from 'react'
import { useDispatch } from 'react-redux'
import { estudianteFormCredencialNueva } from '../../action/router'

export const BotonNuevaCredencial = () => {
    const dispatch = useDispatch()

    const handleNuevo = () => {
        dispatch(estudianteFormCredencialNueva());
    }
    return (
        <button className='btn btn-primary' onClick={handleNuevo}>Nueva Credencial</button>
    )
}
