import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { crearEstudiante } from '../../../action/estudiante';
import { estudianteTablaEstudiantes } from '../../../action/router';
import { clearUploadFile, startUploadingFile } from '../../../helpers/thunks';
import { useForm } from '../../../hooks/useForm';

export const FormEstudianteNuevo = () => {
    const dispatch = useDispatch();
    const { file } = useSelector(state => state.file);
    const [estudiante, handleInputChange] = useForm({
        rut: '',
        password: '',
        nombres: '',
        apellidoPaterno:'',
        apellidoMaterno:'',
        fechaNacimiento: '',
    });
    const{ rut, password, nombres, apellidoPaterno, apellidoMaterno, fechaNacimiento } = estudiante;
    
    const handleEnviar = (e) => {
        e.preventDefault()
        if( rut === '' || nombres === '' || apellidoPaterno === '' || apellidoMaterno === '' || fechaNacimiento === '' || password === '' ){
            return Swal.fire({
                icon: 'warning',
                title: 'Faltan campos por rellenar',
                showConfirmButton: false,
                timer: 1000
            });
        }
        if(file){
            estudiante.fotografia = file;
        }
        console.log('estudiante crear ---->', estudiante)
        dispatch(crearEstudiante(estudiante));
        dispatch(clearUploadFile(false))
        dispatch(estudianteTablaEstudiantes())
    }
    
    const handleUploadFile = (e) =>{
        Swal.fire({
            icon: 'warning',
            title: 'Subiendo fotografia espere...',
            showConfirmButton: false,
            timer: 4000
        });
        const file = e.target.files[0];
        if( file === 0 ) return;
        dispatch(startUploadingFile(file));
    };

    return (
        <form className='card'>
            <div className='card-body'>
                <h5>Formulario ingreso estudiante</h5>
                <div className="row mb-3 mt-3">
                    <label htmlFor="rut" className="col-2 col-form-label">Rut</label>
                    <div className="col-4">
                        <input 
                            type="text" 
                            className="form-control" 
                            id="rut"
                            name="rut"
                            value={ rut }
                            onChange={ handleInputChange }
                        />
                    </div>
                    <label htmlFor="nombres" className="col-2 col-form-label">Nombres</label>
                    <div className="col-4">
                        <input 
                            type="text" 
                            className="form-control" 
                            id="nombres"
                            name="nombres"
                            value={ nombres }
                            onChange={ handleInputChange }
                        />
                    </div>
                </div>
                <div className="row mb-3 mt-3">
                    <label htmlFor="apellidoPaterno" className="col-2 col-form-label">Apellido paterno</label>
                    <div className="col-4">
                        <input 
                            type="text" 
                            className="form-control" 
                            id="apellidoPaterno"
                            name="apellidoPaterno"
                            value={ apellidoPaterno }
                            onChange={ handleInputChange }
                        />
                    </div>
                    <label htmlFor="apellidoMaterno" className="col-2 col-form-label">Apellido materno</label>
                    <div className="col-4">
                        <input 
                            type="text" 
                            className="form-control" 
                            id="apellidoMaterno"
                            name="apellidoMaterno"
                            value={ apellidoMaterno }
                            onChange={ handleInputChange }
                        />
                    </div>
                </div>
                <div className="row mb-3 mt-3">
                        <label htmlFor="fotografia" className="col-2 col-form-label">Fotografia</label>
                        <div className="col-5">
                            <input 
                                id="inputGroupFile01"
                                type="file" 
                                className="form-control" 
                                name="fotografia"
                                onChange={ handleUploadFile }
                            />
                        </div>
                    </div>
                <div className="row mb-3 mt-3">
                    <label htmlFor="fechaNacimiento" className="col-2 col-form-label">Fecha nacimiento</label>
                    <div className="col-4">
                        <input 
                            type="date" 
                            className="form-control" 
                            id="fechaNacimiento"
                            name="fechaNacimiento"
                            value={ fechaNacimiento }
                            onChange={ handleInputChange }
                        />
                    </div>
                </div>
                <div className="row mb-3 mt-3">
                    <label htmlFor="password" className="col-2 col-form-label">Contraseña</label>
                    <div className="col-4">
                        <input 
                            type="password" 
                            className="form-control" 
                            id="password"
                            name="password"
                            value={ password }
                            onChange={ handleInputChange }
                        />
                    </div>
                </div>
                <div className="row mb-3 mt-3">
                    <div className="col-4">
                        <button 
                            type="submit" 
                            className="btn btn-primary" 
                            onClick={ handleEnviar }
                        >Crear</button>
                    </div>
                </div>
            </div>
        </form>
    )
}
