import * as React from "react"
import { AdministradorEstudianteScreen } from "../components/administradorEstudiante/AdministradorEstudianteScreen"
import Layout from '../components/layout'


const administradorEstudiante = () => {
  return (
    <Layout>
      <div className="container-xl">
        <AdministradorEstudianteScreen  />
      </div>
    </Layout>
  )
}

export default administradorEstudiante
