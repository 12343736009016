import React from 'react'
import { useDispatch } from 'react-redux';
import { activarCredencialEstudiante, actualizarCredencialEstudiante } from '../../../action/credencialesEstudiantes';
import { estudianteFormCredencialActualizar } from '../../../action/router';

export const RowCredencial = ({ credencial, estudiante, establecimientos, establecimientoActivo, index }) => {
    const dispatch = useDispatch();
    const establecimientoPrincipal =  establecimientos.find(e=> e._id === establecimientoActivo);
    const { _id, email, cargo, estado } = credencial;

    const handleActivar = () => {
        credencial.estado = 'true'
        dispatch(actualizarCredencialEstudiante(credencial))
    }
    
    const handleDesactivar = () => {
        credencial.estado = 'false'
        dispatch(actualizarCredencialEstudiante(credencial))
    }

    const handleActualizar = () => {
        dispatch(activarCredencialEstudiante(_id))
        dispatch(estudianteFormCredencialActualizar())
      }
    

    return (
        <tr>
            <th>{index}</th>
            <td>{estudiante.nombres} {estudiante.apellidoPaterno} {estudiante.apellidoMaterno}</td>
            <td>{cargo}</td>
            <td>{email}</td>
            <td>{establecimientoPrincipal.nombre}</td>
            <td>
                {estado 
                    ? 
                        <div className='d-grid gap-2'>
                            <button className='btn btn-success btn-sm' onClick={ handleDesactivar } >Activo</button>
                        </div>
                    : 
                        <div className='d-grid gap-2'>
                            <button className='btn btn-danger btn-sm' onClick={ handleActivar } >Inactivo</button>
                        </div>
                }
            </td>
            <td>
                <div className='d-grid gap-2'>
                    <button className='btn btn-warning btn-sm' onClick={ handleActualizar } >Actualizar</button>
                </div>
            </td>
        </tr>
    )
}
