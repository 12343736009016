import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { limpiarCredencialesEstudiante, obtenerCredencialesEstudianteEstablecimiento } from '../../../action/credencialesEstudiantes';
import { activarEstudiante } from '../../../action/estudiante';
import { estudianteTablaEstudiantes } from '../../../action/router';
import { Loading } from '../../Loading';
import { RowSinData } from '../../RowSinData';
import { RowCredencial } from './RowCredencial';

export const TablaCredenciales = ({estudianteActivo, estudiantes, credencialesEstudiante, establecimientos, establecimientoActivo}) => {
    const dispatch = useDispatch();
    const estudiantePrincipal = estudiantes.find(e => e._id === estudianteActivo);
    useEffect(() => {
        const filtro = {
            estudiante: estudianteActivo,
            establecimiento: establecimientoActivo,
        }
        const obtenerCredencialesEstudiantePrincipal = () => dispatch(obtenerCredencialesEstudianteEstablecimiento(filtro));
        obtenerCredencialesEstudiantePrincipal()
        // eslint-disable-next-line
    }, [estudianteActivo]);

    const handleRegresar = () => {
        dispatch(limpiarCredencialesEstudiante())
        dispatch(activarEstudiante(""))
        dispatch(estudianteTablaEstudiantes())
    }
    if(!credencialesEstudiante) return <Loading />
    return (
        <div className='card'>
            <div className='card-body'>
                <h6>Tabla Credenciales Estudiante</h6>
                <table className='table table-bordered table-hover'>
                    <thead>
                        <tr>
                            <th scope="col">N°</th>
                            <th scope="col">Estudiante</th>
                            <th scope="col">Cargo</th>
                            <th scope="col">Email</th>
                            <th scope="col">Establecimiento</th>
                            <th scope="col">Estado</th>
                            <th scope="col">Actualizar</th>
                        </tr>
                    </thead>
                    <tbody>
                        {credencialesEstudiante.length > 0 
                            ?
                            credencialesEstudiante.map((e, index)=>(
                                <RowCredencial 
                                    key={e._id}
                                    credencial={e}
                                    estudiante={ estudiantePrincipal }
                                    establecimientos={establecimientos}
                                    establecimientoActivo={establecimientoActivo}
                                    index={ index + 1 }
                                />
                            ))
                            :   
                            <RowSinData />
                        }
                    </tbody>
                    <tfoot>
                        <tr>
                            <td>
                                <button className='btn btn-danger' onClick={handleRegresar}>Regresar</button>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    )
}
