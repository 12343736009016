import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Swal from 'sweetalert2';
import { activarCredencialEstudiante, actualizarCredencialEstudiante } from '../../../action/credencialesEstudiantes';
import { estudianteTablaCredenciales } from '../../../action/router';
import { useForm } from '../../../hooks/useForm';

export const FormEstudianteCredencialActualizar = ({credencialEstudianteActiva}) => {
    const dispatch = useDispatch();
    const { credencialesEstudiante } = useSelector(state=>state.estudiante);
    const credencialEstudiantePrincipal = credencialesEstudiante.find(e=> e._id === credencialEstudianteActiva);
    const [ credencial, handleInputChange ] = useForm(credencialEstudiantePrincipal);
    const { email, cargo } = credencial;

    const handleActualizar = (e) => {
        e.preventDefault()
        if( email === '' || cargo === ''  ){
            return Swal.fire({
                icon: 'warning',
                title: 'Faltan campos por rellenar',
                showConfirmButton: false,
                timer: 1000
            });
        }
        dispatch(actualizarCredencialEstudiante(credencial));
        dispatch(activarCredencialEstudiante(""))
        dispatch(estudianteTablaCredenciales())
    }

    const handleRegresar = (e) =>{
        e.preventDefault()
        dispatch(activarCredencialEstudiante(""))
        dispatch(estudianteTablaCredenciales())
    }

    return (
        <form className='card'>
            <div className='card-body'>
                <h5>Formulario actualizar credencial estudiante</h5>
                <div className="row mb-3 mt-3">
                    <label htmlFor="email" className="col-2 col-form-label">Email</label>
                    <div className="col-4">
                        <input 
                            type="text" 
                            className="form-control" 
                            id="email"
                            name="email"
                            value={ email }
                            onChange={ handleInputChange }
                        />
                    </div>
                </div>
                <div className="row mb-3 mt-3">
                    <label htmlFor="cargo" className="col-2 col-form-label">Cargo</label>
                    <select 
                        className="form-select" 
                        aria-label="Default select example"
                        onChange={handleInputChange}
                        value={cargo}  
                        name="cargo" 
                    >
                        <option value="" defaultValue>Seleccione...</option>
                        <option value="1" defaultValue>Estudiante</option>
                        
                    </select>
                </div>
                <div className="row mb-3 mt-3">
                    <div className='col-12'>
                        <div className='row'>
                            <div className="col-3">
                                <button 
                                    type="submit" 
                                    className="btn btn-primary" 
                                    onClick={ handleActualizar }
                                >Actualizar</button>
                            </div>
                            <div className="col-3">
                                <button 
                                    type="submit" 
                                    className="btn btn-danger" 
                                    onClick={ handleRegresar }
                                >Regresar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}
