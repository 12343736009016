import React from 'react'
import { useDispatch } from 'react-redux';
import { activarEstablecimiento } from '../../../action/establecimiento';
import { estudianteTablaEstudiantes } from '../../../action/router';

export const RowEstablecimiento = ({establecimiento, index}) => {
    const dispatch = useDispatch()
    const { _id, nombre, rbd, rut, razonSocial, estado } = establecimiento;
    
    const handleEstudiantes = () => {
        dispatch(activarEstablecimiento(_id))
        dispatch(estudianteTablaEstudiantes())
    }

    return (
        <tr>
            <th>{index}</th>
            <td>{rut}</td>
            <td>{nombre}</td>
            <td>{rbd}</td>
            <td>{razonSocial}</td>
            <td>
                {estado 
                    ? 
                        <div className='d-grid gap-2'>
                            <button className='btn btn-success btn-sm' disabled >activo</button>
                        </div>
                    : 
                        <div className='d-grid gap-2'>
                            <button className='btn btn-danger btn-sm' disabled>inactivo</button>
                        </div>
                }
            </td>
            
            <td>
                <div className='d-grid gap-2'>
                    <button className='btn btn-warning btn-sm' onClick={ handleEstudiantes } >Estudiantes</button>
                </div>
            </td>
        </tr>
    )
}
