import React from 'react'
import { useDispatch } from 'react-redux'
import { estudianteFormNuevo } from '../../action/router'

export const BotonNuevoEstudiante = () => {
    const dispatch = useDispatch()

    const handleNuevo = () => {
        dispatch(estudianteFormNuevo());
    }
    return (
        <button className='btn btn-primary' onClick={handleNuevo}>Nuevo Estudiante</button>
    )
}
